<template>
  <v-dialog v-model="dialog" scrollable max-width="600" @click:outside="reset">
    <v-card>
      <v-card-title class="headline"
        >{{ this.isEditing ? "Edit" : "Add" }} Swim Badge Order</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
        <v-form @submit.prevent="save" method="post" id="swim-badge-order-form">
          <v-select
            label="Customer *"
            v-model="fields.customer_id"
            :items="customers"
            item-text="full_name"
            item-value="id"
            outlined
            background-color="white"
            :disabled="isEditing"
            :error="errors.hasOwnProperty('customer_id')"
            :error-messages="errors['customer_id']"
          >
            <template v-slot:prepend-item>
              <div class="pa-3">
                <v-text-field
                  label="Search Customer"
                  v-model="customerSearchTerm"
                  outlined
                  autofocus
                  dense
                  hide-details
                ></v-text-field>
              </div>
              <v-divider></v-divider>
            </template>
          </v-select>
          <v-select
            label="Badge *"
            v-model="fields.badge_id"
            :items="swimBadges"
            item-text="name"
            item-value="id"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('badge_id')"
            :error-messages="errors['badge_id']"
          ></v-select>
          <v-text-field
            label="Child's Name *"
            v-model="fields.child_name"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('child_name')"
            :error-messages="errors['child_name']"
          ></v-text-field>
          <v-text-field
            label="Quantity *"
            v-model="fields.quantity"
            type="number"
            max="25"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('quantity')"
            :error-messages="errors['quantity']"
          ></v-text-field>
          <v-text-field
            label="Total Amount *"
            v-model="fields.total"
            type="number"
            min="0.00"
            step="1.00"
            prefix="£"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('total')"
            :error-messages="errors['total']"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="reset()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="swim-badge-order-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      isEditing: false,
      dialog: false,
      loading: false,
      swimBadgeOrder: {},
      customerSearchTerm: "",
      fields: {
        customer_id: null,
        badge_id: null,
        child_name: null,
        quantity: 1,
        total: null,
      },
      errors: {},
    };
  },

  computed: {
    customers() {
      let customers = this.$store.getters["arnprior/customersStore/all"];

      if (this.customerSearchTerm !== "") {
        customers = customers.filter((c) => {
          const name = c.full_name.toLowerCase();
          const email = c.email.toLowerCase();
          const customerSearchTerm = this.customerSearchTerm.toLowerCase();

          return (
            name.includes(customerSearchTerm) ||
            email.includes(customerSearchTerm)
          );
        });
      }

      return customers;
    },

    swimBadges() {
      return this.$store.getters["arnprior/ordersStore/allSwimBadges"];
    },
  },

  methods: {
    open(swimBadgeOrder = null) {
      if (swimBadgeOrder !== null) {
        this.isEditing = true;
        this.swimBadgeOrder = swimBadgeOrder;
        this.fields.customer_id = swimBadgeOrder.order.customer_id;
        this.fields.badge_id = swimBadgeOrder.badge_id;
        this.fields.child_name = swimBadgeOrder.child_name;
        this.fields.quantity = swimBadgeOrder.quantity;
        this.fields.total = swimBadgeOrder.order.total;
      }
      this.dialog = true;
    },

    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.swimBadgeOrderId = this.swimBadgeOrder.id;
      }

      this.$store
        .dispatch("arnprior/ordersStore/saveSwimBadgeOrders", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    reset() {
      this.dialog = false;
      this.isEditing = false;
      this.loading = false;
      this.swimBadgeOrder = {};
      this.fields = {
        customer_id: null,
        badge_id: null,
        child_name: null,
        quantity: 1,
        total: null,
      };
      this.$refs.formWrapper.scrollTop = 0;
    },
  },
};
</script>
