<template>
  <div>
    <v-container>
      <v-row no-gutters align="start">
        <v-breadcrumbs
          :items="breadcrumbs"
          class="pa-0 pb-2"
          divider=">"
        ></v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-btn small depressed :to="{ name: 'module-arnprior-customers' }"
          ><v-icon left>mdi-arrow-left</v-icon>Back to Customers</v-btn
        >
      </v-row>
      <h1 class="mb-4">{{ customer.full_name }}</h1>
      <v-row>
        <v-col cols="8">
          <v-card outlined class="mb-4">
            <v-card-title class="grey lighten-4">Bookings</v-card-title>
            <v-divider></v-divider>
            <v-data-table
              :headers="tableHeaders"
              :items="customer.orders"
              class="mt-4"
              no-data-text="No Bookings found"
            >
              <template v-slot:item.start_date_time.datetime="{ item }"
                >{{ item.start_date_time.date }}
                {{ item.start_date_time.time }}</template
              >
              <template v-slot:item.end_date_time.datetime="{ item }"
                >{{ item.end_date_time.date }}
                {{ item.end_date_time.time }}</template
              >
              <template v-slot:item.total="{ item }"
                >£{{ item.total }}</template
              >
              <template v-slot:item.outstanding_total="{ item }">
                <span
                  class="red--text"
                  v-if="item.outstanding_total === item.total"
                >
                  £{{ item.outstanding_total }}
                </span>
                <span
                  class="green--text"
                  v-else-if="item.outstanding_total === '0.00'"
                >
                  £{{ item.outstanding_total }}
                </span>
                <span class="orange--text" v-else>
                  £{{ item.outstanding_total }}
                </span>
              </template>
              <template v-slot:item.has_pod_bookings="{ item }">
                <v-icon color="green" v-if="item.has_pod_bookings"
                  >mdi-check-circle</v-icon
                >
              </template>
              <template v-slot:item.has_swim_bookings="{ item }">
                <v-icon color="green" v-if="item.has_swim_bookings"
                  >mdi-check-circle</v-icon
                >
              </template>
              <template v-slot:item.has_party_pod_bookings="{ item }">
                <v-icon color="green" v-if="item.has_party_pod_bookings"
                  >mdi-check-circle</v-icon
                >
              </template>
              <template v-slot:item.has_pool_party_bookings="{ item }">
                <v-icon color="green" v-if="item.has_pool_party_bookings"
                  >mdi-check-circle</v-icon
                >
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="green lighten-4 green--text"
                      v-on="on"
                      :to="{
                        name: 'module-arnprior-orders-individual',
                        params: { orderId: item.id },
                      }"
                    >
                      <v-icon x-small>mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  <span>View</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card>
          <v-card outlined class="mb-4">
            <v-card-title class="grey lighten-4"
              >Gift Voucher Orders</v-card-title
            >
            <v-divider></v-divider>
            <v-data-table
              :headers="giftVouchersTableHeaders"
              :items="customer.gift_vouchers"
              no-data-text="The customer hasn't bought any Gift Vouchers yet"
            >
              <template v-slot:item.amount="{ item }">
                £{{ item.amount }}
              </template>
              <template v-slot:item.remaining_amount="{ item }">
                £{{ item.remaining_amount }}
              </template>
              <template v-slot:item.created_at="{ item }">
                {{ item.formatted_dates.created_at }}
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="blue lighten-4 blue--text"
                      v-on="on"
                      class="mr-2"
                      @click="$refs.giftVoucherDialog.open(item)"
                    >
                      <v-icon x-small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="red lighten-4 red--text"
                      v-on="on"
                      @click="openGiftVoucherDelete(item)"
                    >
                      <v-icon x-small>mdi-archive</v-icon>
                    </v-btn>
                  </template>
                  <span>Archive</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card>
          <v-card outlined>
            <v-card-title class="grey lighten-4"
              >Swim Badge Orders</v-card-title
            >
            <v-divider></v-divider>
            <v-data-table
              :headers="swimBadgesTableHeaders"
              :items="customer.swim_badges"
              class="mt-4"
              no-data-text="The customer hasn't bought any Swim Badges yet"
            >
              <template v-slot:item.total="{ item }">
                £{{ item.order.total }}
              </template>
              <template v-slot:item.created_at="{ item }">
                {{ item.formatted_dates.created_at }}
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="blue lighten-4 blue--text"
                      v-on="on"
                      class="mr-2"
                      @click="$refs.swimBadgeOrderDialog.open(item)"
                    >
                      <v-icon x-small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="red lighten-4 red--text"
                      v-on="on"
                    >
                      <v-icon x-small>mdi-archive</v-icon>
                    </v-btn>
                  </template>
                  <span>Archive</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card outlined>
            <v-card-title class="grey lighten-4">
              Basic Details
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    class="mr-2"
                    @click="$refs.customerDialog.open(customer)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </v-card-title>
            <v-divider></v-divider>
            <v-simple-table>
              <tbody>
                <tr>
                  <th>Full Name</th>
                  <td>{{ customer.full_name }}</td>
                </tr>
                <tr>
                  <th>Email</th>
                  <td>{{ customer.email }}</td>
                </tr>
                <tr>
                  <th>Phone</th>
                  <td>{{ customer.phone }}</td>
                </tr>
                <tr v-if="customer.billing_address_id">
                  <th>Billing Address</th>
                  <td style="padding-top: 13px; padding-bottom: 13px">
                    <div v-if="customer.billing_address.company">
                      {{ customer.billing_address.company }}
                    </div>
                    <div>{{ customer.billing_address.line_1 }}</div>
                    <div v-if="customer.billing_address.line_2">
                      {{ customer.billing_address.line_2 }}
                    </div>
                    <div>{{ customer.billing_address.city }}</div>
                    <div>{{ customer.billing_address.postcode }}</div>
                    <div>{{ customer.billing_address.county }}</div>
                    <div>{{ customer.billing_address.country }}</div>
                  </td>
                </tr>
                <tr v-if="customer.shipping_address_id">
                  <th>Shipping Address</th>
                  <td style="padding-top: 13px; padding-bottom: 13px">
                    <div v-if="customer.shipping_address.company">
                      {{ customer.shipping_address.company }}
                    </div>
                    <div>{{ customer.shipping_address.line_1 }}</div>
                    <div v-if="customer.shipping_address.line_2">
                      {{ customer.shipping_address.line_2 }}
                    </div>
                    <div>{{ customer.shipping_address.city }}</div>
                    <div>{{ customer.shipping_address.postcode }}</div>
                    <div>{{ customer.shipping_address.county }}</div>
                    <div>{{ customer.shipping_address.country }}</div>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="deleteGiftVoucher.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Archive Gift Voucher</v-card-title>
        <v-card-text
          >Are you sure you wish to archive
          {{ deleteGiftVoucher.giftVoucher.code }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetGiftVoucherDelete"
            >No, cancel</v-btn
          >
          <v-btn
            color="success"
            depressed
            :loading="deleteGiftVoucher.loading"
            @click="saveGiftVoucherDelete"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <customer-dialog ref="customerDialog" />
    <gift-voucher-dialog ref="giftVoucherDialog" />
    <swim-badge-order-dialog ref="swimBadgeOrderDialog" />
  </div>
</template>

<script>
import CustomerDialog from "./components/CustomerDialog";
import GiftVoucherDialog from "@/modules/arnprior/views/vouchers/components/GiftVoucherDialog";
import SwimBadgeOrderDialog from "@/modules/arnprior/views/orders/swim-badges/components/SwimBadgeOrderDialog";

export default {
  components: {
    CustomerDialog,
    GiftVoucherDialog,
    SwimBadgeOrderDialog,
  },

  data() {
    return {
      breadcrumbs: [
        {
          text: "Customers",
          disabled: false,
          to: { name: "module-arnprior-customers" },
          exact: true,
        },
        {
          text: "Customer",
          disabled: true,
        },
      ],
      tableHeaders: [
        {
          text: "Start Date/Time",
          value: "start_date_time.datetime",
        },
        { text: "End Date/Time", value: "end_date_time.datetime" },
        { text: "Total", value: "total" },
        { text: "Outstanding Total", value: "outstanding_total" },
        { text: "Pod Booking", value: "has_pod_bookings" },
        { text: "Swim Booking", value: "has_swim_bookings" },
        { text: "Party Pod Booking", value: "has_party_pod_bookings" },
        { text: "Pool Party Booking", value: "has_pool_party_bookings" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],

      giftVouchersTableHeaders: [
        { text: "Date Ordered", value: "created_at" },
        { text: "Code", value: "code" },
        { text: "Total Amount", value: "amount" },
        { text: "Remaining Amount", value: "remaining_amount" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      swimBadgesTableHeaders: [
        { text: "Date Ordered", value: "created_at" },
        { text: "Badge", value: "badge.name" },
        { text: "Child's Name", value: "child_name" },
        { text: "Quantity", value: "quantity" },
        { text: "Total Cost", value: "total", sortable: false },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      deleteGiftVoucher: {
        dialog: false,
        giftVoucher: {},
        loading: false,
      },
    };
  },

  computed: {
    customer() {
      return this.$store.getters["arnprior/customersStore/get"];
    },

    bookings() {
      return this.$store.getters["arnprior/customersStore/bookings"];
    },
  },

  methods: {
    openGiftVoucherDelete(giftVoucher) {
      this.deleteGiftVoucher.giftVoucher = giftVoucher;
      this.deleteGiftVoucher.dialog = true;
    },

    resetGiftVoucherDelete() {
      this.deleteGiftVoucher.dialog = false;
      this.deleteGiftVoucher.giftVoucher = {};
      this.deleteGiftVoucher.loading = false;
    },

    saveGiftVoucherDelete() {
      this.deleteGiftVoucher.loading = true;

      this.$store
        .dispatch("arnprior/vouchersStore/deleteGiftVoucher", {
          appId: this.$route.params.id,
          giftVoucherId: this.deleteGiftVoucher.giftVoucher.id,
        })
        .then(() => {
          this.resetGiftVoucherDelete();
        })
        .catch(() => {
          this.deleteGiftVoucher.loading = false;
        });
    },
  },

  created() {
    this.breadcrumbs.push({
      text: this.customer.full_name,
      disabled: true,
    });
  },
};
</script>
