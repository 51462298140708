<template>
  <v-dialog v-model="dialog" scrollable max-width="600" @click:outside="reset">
    <v-card>
      <v-card-title class="headline"
        >{{ this.isEditing ? "Edit" : "Add" }} Discount Code</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
        <v-form @submit.prevent="save" method="post" id="discount-code-form">
          <v-select
            label="Customer *"
            v-model="fields.customer_id"
            :items="customers"
            item-text="full_name"
            item-value="id"
            outlined
            background-color="white"
            :disabled="isEditing"
            :error="errors.hasOwnProperty('customer_id')"
            :error-messages="errors['customer_id']"
          >
            <template v-slot:prepend-item>
              <div class="pa-3">
                <v-text-field
                  label="Search Customer"
                  v-model="customerSearchTerm"
                  outlined
                  autofocus
                  dense
                  hide-details
                ></v-text-field>
              </div>
              <v-divider></v-divider>
            </template>
          </v-select>
          <v-text-field
            label="Code *"
            v-model="fields.code"
            type="text"
            max="25"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('code')"
            :error-messages="errors['code']"
          ></v-text-field>
          <v-text-field
            label="Total Amount *"
            v-model="fields.amount"
            type="number"
            min="0.00"
            step="1.00"
            prefix="£"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('amount')"
            :error-messages="errors['amount']"
          ></v-text-field>
          <v-text-field
            label="Remaining Amount *"
            v-model="fields.remaining_amount"
            type="number"
            min="0.00"
            step="1.00"
            prefix="£"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('remaining_amount')"
            :error-messages="errors['remaining_amount']"
          ></v-text-field>
          <v-text-field
            label="Valid From *"
            v-model="fields.valid_from"
            type="datetime-local"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('valid_from')"
            :error-messages="errors['valid_from']"
          ></v-text-field>
          <v-text-field
            label="Valid Until *"
            v-model="fields.valid_to"
            type="datetime-local"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('valid_to')"
            :error-messages="errors['valid_to']"
          ></v-text-field>
          <v-text-field
            label="Recipient Name *"
            v-model="fields.recipient_name"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('recipient_name')"
            :error-messages="errors['recipient_name']"
          ></v-text-field>
          <v-textarea
            label="Message *"
            rows="3"
            v-model="fields.message"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('message')"
            :error-messages="errors['message']"
          ></v-textarea>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="reset()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="discount-code-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      isEditing: false,
      dialog: false,
      loading: false,
      giftVoucher: {},
      customerSearchTerm: "",
      fields: {
        customer_id: null,
        code: null,
        amount: null,
        remaining_amount: null,
        valid_from: null,
        valid_to: null,
        recipient_name: null,
        message: null,
      },
      errors: {},
    };
  },

  computed: {
    customers() {
      let customers = this.$store.getters["arnprior/customersStore/all"];

      if (this.customerSearchTerm !== "") {
        customers = customers.filter((c) => {
          const name = c.full_name.toLowerCase();
          const email = c.email.toLowerCase();
          const customerSearchTerm = this.customerSearchTerm.toLowerCase();

          return (
            name.includes(customerSearchTerm) ||
            email.includes(customerSearchTerm)
          );
        });
      }

      return customers;
    },
  },

  methods: {
    open(giftVoucher = null) {
      if (giftVoucher !== null) {
        const validFrom = new Date(giftVoucher.valid_from);
        const validTo = new Date(giftVoucher.valid_to);

        this.isEditing = true;
        this.giftVoucher = giftVoucher;
        this.fields.customer_id = giftVoucher.order.customer_id;
        this.fields.code = giftVoucher.code;
        this.fields.valid_from = giftVoucher.valid_from
          ? validFrom.toISOString().substr(0, 16)
          : null;
        this.fields.valid_to = giftVoucher.valid_to
          ? validTo.toISOString().substr(0, 16)
          : null;
        this.fields.amount = giftVoucher.amount;
        this.fields.remaining_amount = giftVoucher.remaining_amount;
        this.fields.recipient_name = giftVoucher.recipient_name;
        this.fields.message = giftVoucher.message;
      }
      this.dialog = true;
    },

    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.giftVoucherId = this.giftVoucher.id;
      }

      this.$store
        .dispatch("arnprior/vouchersStore/saveGiftVoucher", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    reset() {
      this.dialog = false;
      this.isEditing = false;
      this.loading = false;
      this.giftVoucher = {};
      this.fields = {
        customer_id: null,
        code: null,
        amount: null,
        remaining_amount: null,
        valid_from: null,
        valid_to: null,
        recipient_name: null,
        message: null,
      };
      this.$refs.formWrapper.scrollTop = 0;
    },
  },
};
</script>
